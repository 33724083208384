import React from "react";
import Layout from "../component/layout";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import HomeImage from "../images/custom-healthcare-home-image.png";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import TimelineV2Component from "../component/TimelineV2Component";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import FooterComponent from "../component/footerComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import BorderCards from "../component/BorderCards";
import { graphql } from "gatsby";

const ServiceCustomHealthcare = (props) => {
  const seo = {
    title: "Custom Healthcare Software Development Solutions | Motomtech",
    metaDesc:
      "We help our healthcare providers attract new patients, improve care, and increase efficiency through custom software development solutions.",
  };
  const {
    data: {
      wpPage: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homeImage },
            },
          },
        },
        aboutUsHomeSection: {
          aboutUsHomeTitle,
          aboutUsHomeDescription,
          aboutUsHomeSolutionTitle,
          aboutUsHomeSolutionTextOne,
          aboutUsHomeSolutionTextTwo,
          aboutUsHomeSolutionTextThree,
        },
        careerPositionSection: {
          careerPositionTitle,
          careerPositionDescription,
          careerPositionFornend,
          careerPositionBackend,
          careerPositionDevops,
          careerPositionDesigner,
          careerPositionQa,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionSupport,
          careerPositionCostumerSupport,
          careerPositionContentManager,
          careerPositionSocialMediaMarketing,
          careerPositionMarketingManager,
          careerPositionTechnicalWriter,
          careerPositionText,
          careerPositionLocation,
        },
        careerDescriptionSection: {
          careerDescriptionTitle,
          careerDescriptionFrontend,
          careerDescriptionBackend,
          careerDescriptionDevops,
          careerDescriptionUxdesigner,
          careerDescriptionQa,
          careerDescriptionProjectManagement,
          careerDescriptionProjectManager,
          careerDescriptionCostumerSupport,
          careerDescriptionContentManager,
          careerDescriptionSocialMediaMarketingSpecialist,
          careerDescriptionMarketingManager,
        },
        processSection: {
          processSubtitle,
          processDescription,
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
          processAgileTitle,
          processAgileDescription,
        },
      },
    },
  } = props;
  const questions1 = [
    {
      title: processAnalysisTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAnalysisDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processProductDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 2,
    },
  ];
  const questions2 = [
    {
      title: processDesigningTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processDesigningDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 3,
    },
    {
      title: processAgileTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAgileDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 4,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={homeImage}
        homeButton={"Request our services"}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={aboutUsHomeTitle}
        description1={aboutUsHomeDescription}
        description2={aboutUsHomeSolutionTitle}
        description3={aboutUsHomeSolutionTextOne}
        description4={aboutUsHomeSolutionTextTwo}
        description5={aboutUsHomeSolutionTextThree}
      />
      <BorderCards
        borderCardsTitle={careerPositionTitle}
        borderCardsText1={careerPositionDescription}
        borderCardsText2={careerPositionFornend}
        borderCardsText3={careerPositionBackend}
        borderCardsText4={careerPositionDevops}
        borderCardsText5={careerPositionDesigner}
        borderCardsText6={careerPositionQa}
        borderCardsText7={careerPositionProjectManagement}
        borderCardsText8={careerPositionProjectManager}
        borderCardsText9={careerPositionSupport}
        borderCardsText10={careerPositionCostumerSupport}
        borderCardsText11={careerPositionContentManager}
        borderCardsText12={careerPositionSocialMediaMarketing}
        borderCardsText13={careerPositionMarketingManager}
        borderCardsText14={careerPositionTechnicalWriter}
        borderCardsText15={careerPositionText}
        borderCardsText16={careerPositionLocation}
      />
      <TimelineV2Component
        timelineTitle={careerDescriptionTitle}
        timelineDescription={careerDescriptionFrontend}
        timelineTextTitle1={
          <p dangerouslySetInnerHTML={{ __html: careerDescriptionBackend }} />
        }
        timelineTextDescription1={careerDescriptionDevops}
        timelineTextTitle2={
          <p
            dangerouslySetInnerHTML={{ __html: careerDescriptionUxdesigner }}
          />
        }
        timelineTextDescription2={careerDescriptionQa}
        timelineTextTitle3={
          <p
            dangerouslySetInnerHTML={{
              __html: careerDescriptionProjectManagement,
            }}
          />
        }
        timelineTextDescription3={careerDescriptionProjectManager}
        timelineTextTitle4={
          <p
            dangerouslySetInnerHTML={{
              __html: careerDescriptionCostumerSupport,
            }}
          />
        }
        timelineTextDescription4={careerDescriptionContentManager}
        timelineTextTitle5={
          <p
            dangerouslySetInnerHTML={{
              __html: careerDescriptionSocialMediaMarketingSpecialist,
            }}
          />
        }
        timelineTextDescription5={careerDescriptionMarketingManager}
      />
      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={processSubtitle}
        subtitle={processDescription}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={
            "Software Quality is done better with Motomtech"
          }
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceCustomHealthcare;
export const query = graphql`
  {
    wpPage(slug: { eq: "service-custom-healthcare-software-development" }) {
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      aboutUsHomeSection {
        aboutUsHomeTitle
        aboutUsHomeDescription
        aboutUsHomeSolutionTitle
        aboutUsHomeSolutionTextOne
        aboutUsHomeSolutionTextTwo
        aboutUsHomeSolutionTextThree
      }
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionSupport
        careerPositionCostumerSupport
        careerPositionContentManager
        careerPositionSocialMediaMarketing
        careerPositionMarketingManager
        careerPositionTechnicalWriter
        careerPositionText
        careerPositionLocation
      }
      careerDescriptionSection {
        careerDescriptionTitle
        careerDescriptionFrontend
        careerDescriptionBackend
        careerDescriptionDevops
        careerDescriptionUxdesigner
        careerDescriptionQa
        careerDescriptionProjectManagement
        careerDescriptionProjectManager
        careerDescriptionCostumerSupport
        careerDescriptionContentManager
        careerDescriptionSocialMediaMarketingSpecialist
        careerDescriptionMarketingManager
      }
      processSection {
        processSubtitle
        processDescription
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
        processAgileTitle
        processAgileDescription
      }
    }
  }
`;
